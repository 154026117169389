<template>
  <div id="dice-app">
    <div>
      <diceSelector />
      <diceCounter />
      <div
        id="dices"
        class="grid"
      >
        <dice
          v-for="num in diceState.amountOfDice"
          :key="num"
          :die-num="diceState.dices[diceState.selectedDie].dieNum"
        />
      </div>
    </div>
    <bbutton
      id="btn"
      class="btn"
      @buttonClick="rollDice()"
    >
      <h1 class="text">
        {{ getButtonText }}
      </h1>
    </bbutton>
  </div>
</template>

<script>
import diceSelector from "../components/dice.selector.vue";
import diceCounter from "../components/dice.counter.vue";
import dice from "../components/dice.vue";
import bbutton from "../components/button.vue";
import diceState, { rollDice } from "../dice.state";

export default {
  name: "App",
  components: {
    diceSelector,
    diceCounter,
    dice,
    bbutton,
  },
  data() {
    return {
      diceState,
      diceContainerHeigth: 200,
      rollDice,
    };
  },
  computed: {
    getButtonText() {
      return diceState.dieSum ? `=${diceState.dieSum}` : "ROLL";
    },
  },
  mounted() {
    this.setDiceContainerHeigth();
  },
  methods: {
    setDiceContainerHeigth() {
      const diceContainer = document.getElementById("dices");
      const button = document.getElementById("btn").getBoundingClientRect();
      const containerHeigth =
        button.top - diceContainer.getBoundingClientRect().top;
      diceContainer.style.maxHeight = `${containerHeigth}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
#dice-app {
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 auto;
  height: 100%;
  .grid {
    margin: 1rem;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  .sum {
    position: absolute;
    bottom: 0;
    font-size: 3.3rem;
    margin: 0;
    text-align: center;
  }
  .btn {
    margin-top: auto;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
}
</style>
